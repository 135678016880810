<template>
  <div class="jigsaw">
    <div class="jigsaw-info relative">
      <p class="mb-4 text-xl font-semibold" v-if="!started">
        Piece together this jigsaw puzzle in 60 seconds to get your message.
        Press <span class="font-bold">START</span> to begin.
      </p>
    </div>
    <div
      class="jigsaw-start jigsaw-container flex items-center justify-center w-full bg-nColorBlue"
      v-if="!started"
    >
      <div>
        <button
          class="bg-nButtonGreen px-20 rounded-xl py-4 text-lg font-semibold uppercase tracking-wider hover:scale-105
      transform transition-all duration-400 ease-in-out"
          @click="initJigsaw()"
        >
          Start
        </button>
      </div>
    </div>
    <div
      v-if="started"
      class="jigsaw-exp jigsaw-container relative top-0 left-0 w-full bg-nColorBlue"
    >
      <transition name="fade">
        <!-- JIGSAW CANVAS -->
        <div id="canvas-wrap" v-if="!completed">
          <canvas id="canvas"></canvas>
          <canvas class="hide" id="image"></canvas>
          <canvas class="hide" id="image-preview"></canvas>
        </div>
        <div
          class="completed h-full flex flex-col justify-around items-center p-4 md:p-0"
          v-else
        >
          <h2
            class="text-lg md:text-3xl font-bold text-white text-center"
            v-if="solved"
          >
            Yay! You took {{ diffTime }} seconds to solve this. <br /><span
              class="font-normal text-base md:text-xl"
              >Click on the image for more information.</span
            >
          </h2>
          <h2 class="text-base md:text-3xl font-bold text-white" v-else>
            Oops! You gave up too soon. No worry, click on the image for more
            information.
          </h2>
          <a class="inline-block relative" :href="link" target="_blank">
            <img :src="jigsawImage" alt="" />
          </a>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import differenceInSeconds from "date-fns/differenceInSeconds";

export default {
  name: "Jigsaw",
  props: ["jigsawImage", "link"],
  components: {},
  created() {
    const img = new Image();
    img.src = this.jigsawImage;

    img.addEventListener("load", (e) => {
      this.assignImageWidth(e.target);
    });
    return this.loadJigsawLibrary();
  },
  mounted() {},
  data() {
    return {
      imgWidth: null,
      imgHeight: null,
      showGiveUp: false,
      startTime: null,
      endTime: null,
      diffTime: null,
      completed: false,
      loading: true,
      started: false,
      solved: false,
    };
  },
  methods: {
    loadJigsawLibrary() {
      console.log("loading...");
      return new Promise((resolve, reject) => {
        this.$loadScript("/library/experiences/jigsaw/js/sound.js")
          .then(() => {
            return this.$loadScript(
              "/library/experiences/jigsaw/js/event-emiter.min.js"
            );
          })
          .then(() => {
            return this.$loadScript(
              "/library/experiences/jigsaw/js/canvas-event.min.js"
            );
          })
          .then(() => {
            return this.$loadScript(
              "/library/experiences/jigsaw/js/canvas-puzzle.min.js"
            );
          })
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          });
      });
    },

    initJigsaw() {
      const vue = this;
      this.started = true;
      this.startTime = new Date();

      setTimeout(() => {
        this.showGiveUp = true;
      }, 30000);

      this.$nextTick(() => {
        var sound = new game.Sound(
          "/library/experiences/jigsaw/sound/click",
          10
        );
        var jsaw = new jigsaw.Jigsaw({
          defaultImage: vue.jigsawImage,
          image: jigsaw.GET["image"],
          maxWidth:
            vue.imgWidth > window.innerWidth * 0.75
              ? window.innerWidth * 0.75
              : vue.imgWidth,
          maxHeight:
            vue.imgHeight > window.innerHeight * 0.75
              ? window.innerHeight * 0.75
              : vue.imgHeight,
          spread: 0.5,
          defaultPieces: 4,
          numberOfPieces: [10, 20, 30, 40, 50, 60, 100],
          piecesNumberTmpl: "%d Pieces",
          shuffled: true,
        });

        if (jigsaw.GET["image"]) {
          jsaw.set_image(jigsaw.GET["image"]);
        }

        jsaw.eventBus.on(jigsaw.Events.PIECES_CONNECTED, function() {
          sound.play();
        });

        jsaw.eventBus.on(jigsaw.Events.JIGSAW_COMPLETE, function() {
          vue.solved = true;
          vue.puzzleComplete();
        });
      });
    },

    puzzleComplete() {
      this.completed = true;
      this.showGiveUp = false;
      this.endTime = new Date();
      this.diffTime = differenceInSeconds(this.endTime, this.startTime);
    },

    assignImageWidth(image) {
      this.imgWidth = image.width;
      this.imgHeight = image.height;
    },
  },
};
</script>

<style scoped>
@import url("/library/experiences/jigsaw/css/style.css");
.jigsaw-container {
  height: 24rem;
}

.fade-enter-from {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}
.fade-enter-active {
  transition: all ease-in-out 0.5s 0.5s;
}

.fade-leave-from {
  opacity: 1;
}
.fade-leave-to {
  opacity: 0;
}
.fade-leave-active {
  transition: all ease-in-out 0.5s;
}
@media (min-width: 768px) {
  .jigsaw-container {
    height: 42rem;
  }
}
</style>
