<template>
  <div class="mx-auto relative" v-if="experienceDetails">
    <div class="text-black flex flex-col h-screen">
      <div class="mt-4 mx-4 md:mx-4 grid md:flex gap-4">
        <div class="w-full">
          <!--EXP DETAIL PAGES-->
          <Celebrations
            v-if="experienceDetails.meta.slug == slugsConfig.celebrate"
            :expDetails="experienceDetails.details"
          />
          <Jigsaw
            v-else-if="experienceDetails.meta.slug == slugsConfig.jigsaw"
            :jigsawImage="experienceDetails.details.jigsaw_assets.url"
            :link="experienceDetails.details.link"
          />
          <ScratchCard
            v-else-if="experienceDetails.meta.slug == slugsConfig.scratchCard"
            :topImage="experienceDetails.details.scratchcard_assets.top_image"
            :revealImage="
              experienceDetails.details.scratchcard_assets.reveal_image.url
            "
            :link="experienceDetails.details.scratchcard_assets.link"
          />
          <SpinTheWheel
            v-else-if="experienceDetails.meta.slug == slugsConfig.spinTheWheel"
            :expDetails="experienceDetails.details"
          />
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Footer from "@/components/Common/Footer.vue";
import slugsConfig from "@/common/slugs";
import Celebrations from "@/components/Experiences/Tools/Celebrations.vue";
import Jigsaw from "@/components/Experiences/Tools/Jigsaw.vue";
import ScratchCard from "@/components/Experiences/Tools/ScratchCard.vue";
import SpinTheWheel from "@/components/Experiences/Tools/SpinTheWheel.vue";

export default {
  name: "ExperienceDetails",
  components: {
    Footer,
    Celebrations,
    Jigsaw,
    ScratchCard,
    SpinTheWheel,
  },
  created() {
    ApiService.get(apiResource.experience.getToolsDetails, {
      experience_link_id: this.experience_link_id,
    })
      .then(({ data }) => {
        this.experienceDetails = data.data;
      })
      .catch(() => {});
  },
  data() {
    return {
      experienceDetails: null,
      company: this.$route.params.company,
      experience_link_id: this.$route.params.experience_link_id,
      slugsConfig,
    };
  },
  methods: {},
};
</script>

<style scoped lang="postcss"></style>
