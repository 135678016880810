<template>
  <div
    class="exp-container bg-nColorBlue flex justify-center items-center relative"
    v-if="expDetails"
  >
    <model-viewer
      ref="modelViewer"
      class="h-full w-full"
      id="modelViewer"
      name="modelViewer"
      :src="expDetails.models.gltf_path"
      :ios-src="expDetails.models.usdz_path"
      shadow-softness="0"
      shadow-intensity="1"
      camera-controls
      :min-camera-orbit="
        expDetails.option === slugsConfig.celebrationOptions.card
          ? 'auto 0deg auto'
          : null
      "
      :max-camera-orbit="
        expDetails.option === slugsConfig.celebrationOptions.card
          ? 'auto 0deg auto'
          : null
      "
      :camera-orbit="
        expDetails.option != slugsConfig.celebrationOptions.card
          ? '70deg 65deg 64'
          : null
      "
      autoplay
      ar
    >
      <template v-slot:ar-button>
        <button id="ar-button">
          View in your space
        </button>
      </template>
    </model-viewer>
  </div>
</template>

<script>
import slugsConfig from "@/common/slugs";

export default {
  name: "Celebrations",
  props: ["expDetails"],
  components: {},
  created() {},
  data() {
    return {
      slugsConfig,
    };
  },
  computed: {},
  methods: {},
};
</script>

<style scoped>
#ar-button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-appearance: none;
  background: -webkit-gradient(to right, green 0%, brown 50%, pink 100%);
  background-image: linear-gradient(to bottom right, #e040fb, #00bcd4);
  background-size: 100%;
  border: none;
  border-radius: 5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  color: #fff;
  cursor: pointer;
  font: 0.75em sans-serif;
  font-weight: 800;
  height: 2rem;
  letter-spacing: 0.05em;
  outline: 0.1em;
  user-select: none;
  width: 10rem;
  position: absolute;
  top: 16px;
  right: 16px;
}

#ar-button:active {
  background-color: #e8eaed;
}

#ar-button:focus {
  outline: none;
}

#ar-button:focus-visible {
  outline: 1px solid #4285f4;
}
model-viewer::part(default-ar-button) {
  top: 16px;
  right: 16px;
}
.exp-container {
  height: 34rem;
}
</style>
